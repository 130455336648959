import homeCss from '../styles/home.css?url';

import NavBar from '~/components/home/NavBar';

import { user } from '~/services/auth.server';
import { Link, useLoaderData } from '@remix-run/react';
import { FlipWords } from '~/components/ui/flip-wrod';
import { Button, Card, CardFooter, CardHeader, Chip, Divider, Image } from '@heroui/react';

import { ArrowRightIcon } from 'lucide-react';
import TestimonialSlider from '~/components/home/TestimonialSlider';

import ClientLogo from '~/components/home/ClientLogo';
import { getHomeBlogs, getHomeTestimonials } from '~/services/api/homeapi.server';

import { getHomeInstaReels } from '~/services/api/homeapi.server';
import ServiceModal from '~/components/home/ServiceModal';
import ReelsSlider from '../components/home/ReelsSlider';

export const links = () => [{ rel: 'stylesheet', href: homeCss }];

export const loader = async ({ request }) => {
  let response = await user({ request });
  let testimonialsResponse = await getHomeTestimonials();
  const instaData = await getHomeInstaReels();
  const blogsData = await getHomeBlogs();

  return {
    user: response,
    testimonials: testimonialsResponse.data || [],
    reels: instaData.data.reels,
    blogs: blogsData.data.items || [],
  };
};

export default function Index() {
  const { user, testimonials, reels, blogs } = useLoaderData();

  const words = ['Faster', 'For Top $', 'the lifestyle', 'the dream'];

  return (
    <div>
      <NavBar user={user} />

      <div className="space-y-20">
        <div className="grid min-h-screen grid-cols-1  md:grid-cols-2 ">
          {/* VIDEO SECTION (on mobile it appears first + covers full screen height) */}
          <div className="relative order-1 h-screen w-full md:order-2 md:h-auto">
            <div className="absolute -left-10 bottom-32 z-40 w-full">
              <div className="flex w-full max-w-md flex-col space-y-10 rounded-lg bg-white/10 p-6 font-arc shadow-lg backdrop-blur-md">
                {/* Profile Image */}
                <div className="text-3xl capitalize">schedule an apointment Today</div>
                <div className="flex animate-pulse items-center justify-end space-x-2  text-2xl text-gray-800">
                  <p>Place Order</p> <ArrowRightIcon className="h-6 w-6" />
                </div>
              </div>
            </div>
            <iframe
              src="https://iframe.mediadelivery.net/embed/130633/84fab9ef-f950-487d-81f3-2d958456bdd9?loop=true&autoplay=true&muted=true&playsinline=true"
              className="pointer-events-none absolute left-0 top-0 h-full w-full object-cover"
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
              allowFullScreen
              autoPlay={true}
            />
          </div>
          {/* TEXT + IMAGES SECTION */}
          <div className="order-2 flex flex-col items-center justify-center space-y-10 px-8 py-12 text-gray-700 md:order-1 md:py-20 md:pl-20 ">
            <div className="w-full font-abril capitalize">
              <div className="text-5xl md:text-6xl">
                Sell
                <FlipWords
                  duration={2000}
                  words={words}
                  className="font-bold italic text-lime-500"
                />{' '}
                <br />
              </div>
              <div className="text-4xl">with top-tier Real Estate marketing</div>
            </div>
            <div className="w-full space-y-5">
              <Divider />
              <div className="font-arc text-xl  text-gray-700 md:text-2xl">
                We help build your brand, marketing your listing is just the beginning.
              </div>
              <Divider />
            </div>
            <div className="grid grid-cols-3 gap-4">
              <ServiceModal
                titleHeader="Exclusive"
                title="Community Videos"
                imageSrc="https://lacasatour.b-cdn.net/assets/service-images/Brooklyn%20City%20Street%20Facade.jpg?class=xsthumbnail"
                description="When it comes to real estate, location is more than just a point on the
                  map—it's a lifestyle. Our professionally produced Community Videos give your
                  buyers an immersive look into the neighborhood's personality, amenities, and
                  charm. From local hotspots to scenic parks, we highlight everything that makes
                  your listing feel like home."
              />
              <ServiceModal
                titleHeader="Custom"
                title="Agent Videos"
                imageSrc="https://lacasatour.b-cdn.net/assets/service-images/Real%20Estate%20Agent%20Photo.jpg?class=xsthumbnail"
                description="In today's highly competitive real estate market, standing out is more than just having a good reputation or a well-designed website—it's about showcasing your unique personality, expertise, and the value you bring to clients. Agent video does exactly that."
              />
              <ServiceModal
                titleHeader="Trending"
                title="Reels"
                imageSrc="https://lacasatour.b-cdn.net/assets/service-images/Reels%20Photo%20by%20Ron%20Lach.jpg?class=xsthumbnail"
                description="Reach more buyers, build more trust, and close more deals with our Social Media Video service—your secret weapon for turning scrolling thumbs into signing pens."
              />
            </div>
            <div className="w-full">
              <Link prefetch="render" className="home-primary-btn rounded-md" to="/services">
                {/* <Button className="" radius="sm" size="lg"> */}
                View More
                {/* </Button> */}
              </Link>
            </div>
          </div>
        </div>
        <div>
          <ClientLogo />
        </div>

        {/* Reels */}
        {reels.length > 0 && (
          <>
            <h2 className="section-heading text-center">Trending From Our Instagram</h2>
            <ReelsSlider reels={reels} />
          </>
        )}
        <div>
          <section className="bg-white py-12">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                <div className="flex flex-col justify-center">
                  <p className="mb-2 text-sm font-semibold text-lime-400">About Us</p>

                  <h2 className="mb-4 font-abril  text-4xl text-gray-900">
                    Empower Real Estate Agents to Succeed
                  </h2>

                  <p className="mb-6 text-gray-600">
                    At <strong>LaCasaTour</strong>, we specialize in comprehensive real estate
                    marketing solutions designed to help agents stand out in a competitive market.
                    From targeted ad campaigns to captivating property visuals, we provide the tools
                    and strategies you need to attract more leads, close more deals, and build
                    lasting relationships. Our dedicated team of experts stays ahead of industry
                    trends, ensuring you can focus on what matters most— serving your clients and
                    growing your brand.
                  </p>

                  <div className="flex space-x-8">
                    <div className="text-center md:text-left">
                      <p className="text-3xl font-bold text-gray-900">10+ Years</p>
                      <p className="text-gray-500">of real estate marketing excellence</p>
                    </div>

                    <div className="text-center md:text-left">
                      <p className="text-3xl font-bold text-gray-900">1000+ Agents</p>
                      <p className="text-gray-500">served with proven results</p>
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-center">
                  <Image
                    src="https://lacasatour.b-cdn.net/assets/about-us.jpg?className=xsthumbnail"
                    alt="People looking at a tablet"
                    className="h-auto w-full rounded-lg shadow-md"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        {testimonials.length > 0 && (
          <div className="space-y-4">
            <h2 className="section-heading text-center">What Our Clients Say</h2>
            <TestimonialSlider testimonials={testimonials} />
          </div>
        )}

        {/* =======blogs====== */}
        {blogs.length > 0 && (
          <div className="container space-y-4">
            <h2 className="section-heading text-center">Latest Blogs</h2>

            <div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3">
              {blogs.slice(0, 3).map((blog) => (
                <div className="max-w-sm rounded-lg border border-gray-200 bg-white shadow-sm">
                  <Link to={`/blogs/${blog.slug}`}>
                    <img className="h-72 rounded-t-lg" src={blog?.display_image?.url} alt="" />
                  </Link>
                  {blog?.category?.name && (
                    <div className="px-5 pt-3">
                      <Chip color="default">{blog?.category?.name}</Chip>
                    </div>
                  )}
                  <div className="p-5">
                    <Link to={`/blogs/${blog?.slug}`}>
                      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                        {blog?.title}
                      </h5>
                    </Link>
                    <p className="mb-3 font-normal text-gray-700">{blog?.meta_description}</p>
                    <Link
                      to={`/blogs/${blog.slug}`}
                      className="inline-flex items-center rounded-lg bg-lime-400 px-3 py-2 text-center text-sm font-medium text-white hover:bg-lime-500 focus:outline-none focus:ring-4 focus:ring-lime-300"
                    >
                      Read more
                      <svg
                        className="ms-2 h-3.5 w-3.5 rtl:rotate-180"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 10"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M1 5h12m0 0L9 1m4 4L9 9"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-center py-5">
              <Link prefetch="render" className="home-primary-btn rounded-md" to="/blogs">
                View More
              </Link>
            </div>
          </div>
        )}

        <div>{/* <CommunityVideoSection /> */}</div>
        <div>{/* <VirtualStagingSection /> */}</div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
