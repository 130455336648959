import { Image } from '@heroui/react';
import { Play } from 'lucide-react';
import { useEffect, useState } from 'react';

const ReelsSlider = ({ reels = [] }) => {
  const [currentIndex, setcurrentIndex] = useState(null);
  const totalImages = reels.length;
  const sliderSpeed = totalImages * 15;
  const duplicatedTestimonials = reels.concat(reels);

  useEffect(() => {
    document.body.style.overflow = currentIndex !== null ? 'hidden' : 'auto';
  }, [currentIndex]);

  const handleImageClick = (index) => {
    const normalizedIndex = index % totalImages;
    setcurrentIndex(normalizedIndex);
  };

  const closeModal = () => {
    setcurrentIndex(null);
  };

  const prevTestimonial = () => {
    setcurrentIndex((prev) => (prev > 0 ? prev - 1 : totalImages - 1));
  };

  const nextTestimonial = () => {
    setcurrentIndex((prev) => (prev + 1) % totalImages);
  };

  return (
    <div>
      <div id="photos" className="flex flex-col overflow-hidden">
        <div className="relative w-full overflow-hidden">
          <div
            className="slider slider-right flex"
            style={{ animationDuration: `${sliderSpeed}s` }}
          >
            {duplicatedTestimonials.map((reel, idx) => (
              <div key={idx} className="relative w-64 px-2">
                <video controls className="h-auto w-full rounded-md">
                  <source src={reel.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                {/* <div className="absolute bottom-0 left-0 z-10 flex w-full items-center justify-between bg-gradient-to-t from-black to-transparent p-4">
                  <div className="text-white">
                    <p className="font-semibold">{testimonial.title}</p>
                    <p className="text-sm">{testimonial.sub_title}</p>
                  </div>
                  <div className="flex h-10 w-10 items-center justify-center rounded-full bg-white/10 text-lime-400">
                    <Play className="h-8 w-8 animate-pulse" />
                  </div>
                </div> */}
              </div>
            ))}
          </div>
        </div>

        {/* Modal */}
        {currentIndex !== null && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90">
            {/* Close Button */}
            <button
              className="absolute right-4 top-4 text-3xl font-bold text-white"
              onClick={closeModal}
            >
              ×
            </button>

            {/* Image Count */}
            <div className="absolute left-8 top-4 -translate-x-1/2 transform text-lg text-white">
              {currentIndex + 1}/{totalImages}
            </div>

            {/* Video & Title */}
            <div className="relative w-1/2 rounded-xl bg-white p-5 text-center shadow-lg">
              <iframe
                src={`https://iframe.mediadelivery.net/embed/${testimonials[currentIndex].library_id}/${testimonials[currentIndex].video_id}?autoplay=false&loop=false&muted=false&preload=true&responsive=true`}
                loading="lazy"
                className="aspect-video w-full rounded-xl"
                allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
                allowFullScreen
              ></iframe>
            </div>

            {/* Left Button */}
            <button
              className="absolute left-4 text-3xl font-bold text-white"
              onClick={prevTestimonial}
            >
              ‹
            </button>

            {/* Right Button */}
            <button
              className="absolute right-4 text-3xl font-bold text-white"
              onClick={nextTestimonial}
            >
              ›
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReelsSlider;
