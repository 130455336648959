import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Card,
  CardHeader,
  Image,
} from '@heroui/react';

export default function ServiceModal({ titleHeader, title, description, imageSrc }) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <>
      <Card className="relative h-[300px]">
        {/* Black overlay with fading gradient */}
        <div
          onClick={onOpen}
          className="absolute inset-0 z-10 cursor-pointer bg-gradient-to-b from-black/70 to-transparent"
        />

        <CardHeader className="absolute top-1 z-10 flex-col !items-start">
          <p className="text-small font-bold uppercase text-white/60">{titleHeader}</p>
          <h4 className="text-xl font-medium text-white">{title}</h4>
        </CardHeader>
        <Image
          removeWrapper
          alt="Card background"
          className="z-0 h-full w-full object-cover"
          src={imageSrc}
        />
      </Card>
      <Modal size="3xl" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">{title}</ModalHeader>
              <ModalBody>
                <p>{description}</p>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
